@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic,400,300,600);

/*
 * Basic CSS starts 
 */

body{
	font-family: 'Open Sans', Sans Serif;
	font-weight: 400;
	color: #545454;
	-webkit-font-smoothing: antialiased;
}

h1,h2,h3,h4,h5,h6{
	font-family: 'Open Sans', Sans Serif;
	font-weight: 600;
}

.container h1 {
	color: white;
}

p{
	margin:0;
	padding:0;
	color: #787878;
}

Article {
	line-height: 1.6;
	margin-left:15%;
	max-width: 75%;
	color: #663a1d;
}

Article p {
	line-height: 1.6;
	font-size: 18px;
}

img {
	max-width: 100%;
	height: auto;
  }

.small-margin-right
{
	margin-right:5px;
}

.new-characters-lesson
{
	font-size:16px; 
    font-style: italic; 
    padding-left:10px; 
	display:inline-block;
	font-weight: normal;
}

.share-btns__link {
    color: #ffffff;
	background-color: #e47133;
	transition: background-color 0.5s ease;
}

.share-btns__link:hover {
    color: #ffffff;
    background-color: #000;
}


.share-btns__link, .share-btns__link:hover  {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    display: block;
    width: 48px;
    height: 48px;
    line-height: 48px;
    margin: auto;
	border-radius: 50%;
	color: white;
}


.share-btns__item {
    display: inline-block;
}


:focus {
	outline:none;
}

::-moz-focus-inner {
	border:0;
}

.transparent {
    zoom: 1;
    filter: alpha(opacity=80);
    opacity: 0.8;
}

.fa-check
{
	color:green;
}

.fa-times
{
	color:red;
}

a.list-group-item, button.list-group-item {
    color: #555;
}

.color-light-orange {
	background-color: #e47133 !important;
}

.color-red {
    background-color: rgb(177, 146, 117) !important;
}

.color-white {
    background-color: #fff !important;
}

.color-purple {
    background-color: #b200ff !important;
}

.color-light-purple {
    background-color: #D779FF !important;
}

.color-light-blue {
    background-color: #7bcfea !important;
}

.color-light-green {
    background-color: #4cff00 !important;
}

.color-pink {
    background-color: #eb82c9 !important;
}

.color-light-brown {
    background-color: #e28a1e !important;
}

.color-redBrown {
    background-color: #e01818 !important;
}

.color-yellow {
    background-color: #f7f853 !important;
}

.color-grey {
	background-color:rgb(170, 170, 170) !important;
}

.shadow {
 box-shadow: 0 0 30px 5px #999;
}

.letter {
    font-size: 20px;
}

.symbol {
    font-size: 18px;
}



.dropdown-item.active,
.dropdown-item.show,
.dropdown-item:focus,
.btn-primary,
.btn-primary:active,
.btn-primary:visited {
  background-color: #e47133 !important;
}

.btn-primary:hover {
  background-color: #eb9c72 !important;
}

.list-group{
    max-height: 450px;
    margin-bottom: 10px;
	overflow:scroll;
	overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}


.list-group-hover .list-group-item:hover {
    background-color: #5855559c;
}

a.list-group-item:hover{
	background-color:  #c0bfbf9c;
	}


.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
	font-weight: bold;
	color: black;
	background-color: #ffffff;
}

header .navbar { 
	margin: 0;
	background: #d65814;
	border-radius: 0;
	border-bottom: 2px solid rgba(0,0,0,0.2); 
	box-shadow: 0 1px 15px rgba(0,0,0,0.2);
}
header .navbar-default .navbar-nav>li>a {
	margin: 15px 3px;
	padding-top: 6px;
	color: #fff;
	padding-bottom: 6px;
	border-radius: 2px;
}
.nav-link {
	color: #fff !important;
	font-size: 14px;
}

header .navbar-default .navbar-nav>li>a:hover, 
header .navbar-default .navbar-nav>li>a:focus,
header .navbar-default .navbar-nav>li.open>a:hover, 
header .navbar-default .navbar-nav>li.open>a:focus {
	background: #fff;
}

header .navbar-nav .dropdown-menu {
	top: 57px;
	border-radius: 3px;
}
header .navbar-nav .dropdown-menu:after, 
header .navbar-nav .dropdown-menu:before {
	bottom: 100%;
	right: 20px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

header .navbar-nav .dropdown-menu:after {
	border-color: rgba(240, 253, 255, 0);
	border-bottom-color: #fff;
	border-width: 7px;
}

header .navbar-nav .dropdown-menu:before {
	border-color: rgba(194, 225, 245, 0);
	border-bottom-color: #d65814;
	border-width: 8px;
	margin-right: -1px;
}
header .navbar-brand {
	padding: 0;
	height: auto;
}
header .navbar-brand img {
	position: relative;
	top: 5px;
	max-width: 250px;
}

/* 
 * Basic CSS ends 
 */


/*
 * Buttons 
 */


 .on {
    display: none;
}

#fullscreenButton:before {
    content: url('./img/screen.png');
}

#soundButton:before {
    content: url('./img/microphone.png');
}

#keyboardButton:before {
    content: url('./img/processing-02.png');
}

#instructionButton:before {
    content: url('./img/star.png');
}

#lessonButton:before {
    content: url('./img/addressbook.png');
}

#colorButton:before {
    content: url('./img/my_work.png');
}

#customTextButton:before {
    content: url('./img/edit.png');
}

#reloadButton:before {
    content: url('./img/reload.png');
}

.launch-button:hover {
	background-color: #ffffff;
    border-color: rgba(23, 51, 78, 0.15) rgba(23, 51, 78, 0.17) rgba(23, 51, 78, 0.2);
}

    .launch-button:hover:active {
		transition-duration: 0ms;
		background-image: -moz-linear-gradient(rgba(3, 14, 27, 0.02), rgba(3, 14, 27, 0.05));
    }

.launch-button:before {
	display: block;
    height: 32px;
    line-height: 0;
    margin-bottom: 6px;
}

.launch-button {
    background: none repeat scroll 0 0 padding-box transparent;
    background-color: #eaeaea;
    /* border-radius: 2.5px 2.5px 2.5px 2.5px; */
    color: #525C66;
    cursor: pointer;
    display: -moz-box;
    font-size: 75%;
    margin: 1px 3px;
    max-height: 50px;
    max-width: 176px;
    min-width: 50px;
    padding: 5px 5px;
    vertical-align: top;
	white-space: normal;
	border: 2px solid !important;
}

/* .card-header
{
	background-color:#e47133;
	color:white;
} */

/*
 * Buttons end
 */


/*
 * Banner 
 */
 
.banner {
	background: #e47133;
	padding-top: 30px;
	padding-bottom: 30px;
	text-align: center;
}

.banner h2 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 28px;
	line-height: 35px;
	color: #eee;
	text-transform: capitalize;
}
.banner p {
	max-width: 1000px;
	margin: 0 auto;
	margin-top: 10px;
	font-size: 13px;
	line-height: 24px;
	color: #ddd;
	text-transform: uppercase;
	letter-spacing: 0.055em;
}

/* 
 * after banner content
 */
 
.after-banner {
	padding-top: 60px;
}

.after-banner .ab-item {
	max-width: 360px;
	margin: 0 auto;
	margin-top: 30px;
}

.after-banner .ab-item h3 {
	margin-top: 0;
	font-size: 24px;
}

.after-banner .ab-item  p {
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.025em;
}

/*
 * default heading
 */

.default-heading  {
	margin-top: 20px;
	margin-bottom: 10px;
}
.default-heading h2{
	margin-top: 0;
	font-size: 30px;
	color: #676767;
	font-weight: 400;
}

/*
 * typing-area
 */

.typing-area {
	margin-top: 0px;
    padding-bottom: 60px;
	padding-top: 60px;
	background: #fff;
	border-top: 1px solid #ddd;
	position:relative;
}

.text-area {
	padding-top: 60px;
	padding-bottom: 60px;
	background: #fff;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

.call-to-action {
	margin-top: 0px;
	padding-top: 60px;
	padding-bottom: 60px;
	background: #fff;
	border-bottom: 1px solid #ddd;
}

.row-flex {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
  }
  
  .column-flex {
	display: flex;
	flex-direction: column;
	padding-left:20px;
	width:300px;
	flex-basis: 100%;
	flex: 1;
  }

  .column-flex-mid {
	display: flex;
	flex-direction: column;
	padding-left:40px;
	flex-basis: 100%;
	flex: 3;
  }

/*
 * typing-area end
 */


/*
 * Keyboard
 */

 #scoreboard
{
	margin-left:20px; 
	padding:5px; 
	border-style: solid; 
	border-width: 1px; 
	border-color: #cccccc;
}

#center-keyboard-column
{
	padding:40px;
	background-color:black;
}

#center-keyboard-column-inner
{
	margin: auto;
	width: 688px;
}
.keyboard-instruments
{
	display:flex;
	flex-direction: row;
	align-self: flex-end;
	justify-content: flex-start;
	width:100%;
}

.time-box
{
	font-size: 42px; 
	color: #000;
}
.keyboard-buttons
{
	margin-left: auto;
	 opacity: 1
}

.keyboard-layout-button
{
	margin-left:10px;
	padding-top:20px;
	padding-bottom: 20px;
}


 #keyboard {
    margin: 0;
    padding: 0;
    list-style: none;
}

#keyboard li {
    float: left;
    margin: 0 5px 5px 0;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border: 1px solid #f9f9f9;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

#keyboard li.symbol {
        line-height:19px;
        font-size: 18px;
}

.keyboard-marker {
    background-position: center bottom;
    background-image: url('./img/keymarker.png');
    background-repeat: no-repeat;
}


#typing-area table
{
	font-size: 20px; 
	border: none; 
	width: 685px;
}

.keyboard-table
{
	margin-top:-25px; /* compensate for border spacing on top and bottom */
	margin-bottom:-25px;
	border-spacing: 0 25px;
	border-collapse: separate;
}

.keyboard-wrong-character
{
	position:absolute;
	text-align:center;
	padding-top:2px;
	background-color:white;
	left:300px;
	top:200px;
	border:2px solid #000;
	width:70px;
	height:70px;
	color:black;
	font-size:40px
}

#typing-area table tr td
{
    width: 2%;
    text-align: center;
	background-color: #fff;
	border: 1px solid #ccc;
}

#keyboard-area
{
	margin-top: 40px;
	overflow: hidden;
}

#keyboard-thai li {
    float: left;
    margin: 0 5px 5px 0;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border: 1px solid #f9f9f9;
    font-size: 22px;
    border-radius: 5px;
}

#keyboard .capslock, .tab, .left-shift {
    clear: left;
}

#keyboard .tab {
    width: 70px;
}

#keyboard .delete  {
     width: 70px;
}


#keyboard .capslock {
    width: 80px;
}

#keyboard .return {
    width: 77px;
}

#keyboard .left-shift {
    width: 93px;
}

#keyboard .right-shift {
    width: 112px;
}

#keyboard .lastitem {
    margin-right: 0;
}

#keyboard .uppercase {
    text-transform: uppercase;
}

#keyboard .space {
    clear: left;
    width: 681px;
}


/*
 * Keyboard end
 */


 
/*
*  Create Lesson
*/

.createLesson
{
	background-color:#DFDFDF;
	width: 100%; 
	height: 100%; 
	display: flex;
	justify-content: center;
	align-items: center;
	border-width: 5px;
	border-style:  groove;
	border-color:#EEEEEE;
	color:#663A1D
}

/*
*  Start Lesson
*/

.startLesson
{
	position:relative;
	background-color:#DFDFDF;
	width: 100%; 
	height: 100%; 
	display: flex;
	justify-content: center;
	align-items: center;
	border-width: 5px;
	border-style:  groove;
	border-color:#EEEEEE;
	color:#663A1D
}

.startLesson .button-list
{
	position:absolute;
	top:10px;
	right:10px
}

.startLesson .button-list button
{
	margin-left:5px;
}

.startLesson .start-lesson-text-area
{
	flex:0 0 120;
	border: 1px dotted;
	border-radius: 25px;
	padding:90px;
}

.startLesson .start-lesson-text-area p
{
	padding-top:20px;
	margin:auto;
	text-align: center;
}

/*
*  Start Lesson End
*/ 

/*
*  Finished Lesson
*/

.finishedLesson
{
	background-color:#DFDFDF;
	width: 100%; 
	height: 100%; 
	display: flex;
	justify-content: center;
	align-items: center;
	border-width: 5px;
	border-style:  groove;
	border-color:#EEEEEE;
	color:#663A1D
}

.finishedLesson > div
{
	flex:0 0 120;
	border: 1px dotted;
	border-radius: 25px;
	padding:30px;
}

.finishedLesson > div > div
{
	text-align: center
}

/*
*  Finished Lesson End
*/

/*
 * footer
 */

footer {
	margin-top: 80px;
	padding: 50px 0;
	background: #d65814;
	text-align: center;
	border-top: 1px solid rgba(0,0,0,0.1);
}
footer.ffoot {margin-top: 0;}
footer p {
	font-size: 13px;
	line-height: 25px;
	color: #fff;
}
footer p a { 
	font-size: 13px;
	line-height: 24px;
	text-transform: uppercase;
	letter-spacing: 0.055em;
	color: #fff;
}
footer p a:hover { color: #a93c01; }
footer .social a {
	display: inline-block;
	margin: 0 2px;
	margin-top: 5px;
	padding: 2px;
	width: 35px;
	height: 35px;
	line-height: 35px;
	background: #fff;
	font-size: 14px;
	border-radius: 50%;
}

footer .social a  i {
	display: block;
	width: 100%;
	height: 100%;
	line-height: 30px;
	border-radius: 50%;
	border: 1px dashed #d65814;
}

footer .copy-right {
	margin-top: 5px;
}

/*
 * Responsive CSS 
 */

/*
 * Mobile phones 
 */
@media (max-width: 480px){

}

/*
 * Tablets 
 */
@media (max-width: 767px){
	header .navbar-brand img { max-width: 200px; top: 3px; }
	header .navbar-nav .dropdown-menu:after, 
	header .navbar-nav .dropdown-menu:before { display: none;}
	header .navbar-nav .dropdown-menu li a { color: #fff !important;}
	header .navbar-default .navbar-toggle .icon-bar {background: #fff;	}
	header .navbar-collapse { border: 0; box-shadow: none; }
	header .navbar-default .navbar-toggle,
	header .navbar-default .navbar-toggle:hover,
	header .navbar-default .navbar-toggle:focus	{
		background: transparent;
		border-color: #fff;
	}
	


	.default-heading {text-align: center;}
}

@media (max-width: 1368px){
	.finishedLesson > div, .startLesson .start-lesson-text-area {
		border: none;
	}
}
/*
 * Desktop 
 */
@media (max-width: 991px){

}


@media (min-width: 768px) {
    .dropdown-menu {
        width: 250px !important;  
	}
}
